import ForgotPassword from "../Auth/ForgotPassword";
import Login from "../Auth/Login";
import ResetPassword from "../Auth/ResetPassword";
import ErrorPage from "../components/ErrorPage";
import BannerSlider from "../components/pages/Super_Admin/BannerSlider/BannerSlider";
import BannersList from "../components/pages/Super_Admin/BannerSlider/BannersList";
import BannerSliderCreateFormPopup from "../components/pages/Super_Admin/BannerSlider/BannerSliderCreateFormPopup";
import BrandMaster from "../components/pages/Super_Admin/BrandMaster/BrandMaster";
import CategoryMaster from "../components/pages/Super_Admin/CategoryMaster/CategoryMaster";
import CategoryMasterCreateFormPopup from "../components/pages/Super_Admin/CategoryMaster/CategoryMasterCreateFormPopup";
import DetailsPage from "../components/pages/Super_Admin/DetailsPage/DetailsPage";
import OrderSetting from "../components/pages/Super_Admin/OrderSetting/OrderSetting";
import ProductTagMaster from "../components/pages/Super_Admin/ProductTagMaster/ProductTagMaster";
import ProductTagMasterCreateFormPopup from "../components/pages/Super_Admin/ProductTagMaster/ProductTagMasterCreateFormPopup";
import Products from "../components/pages/Super_Admin/Products/Products";
import ProductsCreateFormPopup from "../components/pages/Super_Admin/Products/ProductsCreateFormPopup";
import ProductsStock from "../components/pages/Super_Admin/ProductsStock/ProductsStock";
import ProductsStockCreateFormPopup from "../components/pages/Super_Admin/ProductsStock/ProductsStockCreateFormPopup";
import Store from "../components/pages/Super_Admin/Store/Store";
import User from "../components/pages/Super_Admin/User/User";
import Feature from "../components/pages/Super_Admin/Features/Feature";
import Inquiry from "../components/pages/Super_Admin/Inquiry/Inquiry";
import InquiryDetails from "../components/pages/Super_Admin/Inquiry/InquiryDetails";
import RidersList from "../components/pages/Super_Admin/Rider/RidersList";
import RiderCreateFormPopup from "../components/pages/Super_Admin/Rider/RiderCreateFormPopup";
import OrdersList from "../components/pages/Super_Admin/Order/OrdersList";
import PromoCodesList from "../components/pages/Super_Admin/PromoCode/PromoCodesList";
import PromoCodeCreateFormPopup from "../components/pages/Super_Admin/PromoCode/PromocodeCreateFormPopup";
import OrderSettingCreateFormPopup from "../components/pages/Super_Admin/OrderSetting/OrderSettingCreateFormPopup";
import OrderSettingDetails from "../components/pages/Super_Admin/OrderSetting/OrderSettingDetails";
import DealOfTheDayList from "../components/pages/Super_Admin/DealOfTheDay/DealOfTheDayList";
import DealOfTheDayCreateFormPopup from "../components/pages/Super_Admin/DealOfTheDay/DealOfTheDayCreateFormPopup";
import DeliverySlot from "../components/pages/Super_Admin/DeliverySlot/DeliverySlot";
import DeliveryOffDay from "../components/pages/Super_Admin/DeliveryOffDay/DeliveryOffDay";
import AttributeMaster from "../components/pages/Super_Admin/AttributeMaster/AttributeMaster";
import Attribute from "../components/pages/Super_Admin/Attribute/Attribute";
import CategoryAttributeMap from "../components/pages/Super_Admin/CategoryAttributeMap/CategoryAttributeMap";
import PincodeMaster from "../components/pages/Super_Admin/PincodeMaster/PincodeMaster";
import DeliveryPincode from "../components/pages/Super_Admin/DeliveryPincode/DeliveryPincode";
import DetailsMasterPage from "../components/pages/Super_Admin/DetailsMasterPage/DetailsMasterPage";
import RiderOnlineOfflineDataTabelContainer from "../components/pages/Super_Admin/Rider/RiderOnlineOfflineDataTabelContainer";
import RiderDateWiseDeliveredDataTabelContainer from "../components/pages/Super_Admin/Rider/RiderDateWiseDeliveredDataTabelContainer";
import RiderWalletHistoryDataTabelContainer from "../components/pages/Super_Admin/Rider/RiderWalletHistoryDataTabelContainer";
import RiderLocation from "../components/pages/Super_Admin/Rider/RiderLocation";
import RiderDeliveryLocation from "../components/pages/Super_Admin/Rider/RiderDeliveryLocation";
import StoreWalletHistoryDataTabelContainer from "../components/pages/Super_Admin/Store/StoreWalletHistoryDataTabelContainer";
import StoreCluster from "../components/pages/Super_Admin/Store/StoreCluster";
import OrderDetails from "../components/pages/Super_Admin/Order/OrderDetails";
import UserDetail from "../components/pages/Super_Admin/User/UserDetail";

export const REDIRECT_PATH = {
  DASHBOARD: "/",
  USER: "/user",
  USER_DETAIL: "/user/detail/:userId",
  BRAND_MASTER: "/brand",
  ORDERS: "/orders",
  ORDERS_DETAIL: "/orders/detail/:orderId",
  PRODUCTS: "/products",
  PRODUCTS_Add: "/products/:productId",
  PRODUCTS_STOCK: "/products/stock",
  PRODUCTS_STOCK_Add: "/products/stock/:stockId",
  PRODUCTS_TAG: "/products/tag",
  PRODUCTS_TAG_Add: "/products/tag/:tagId",
  CATEGORY: "/category",
  CATEGORY_Add: "/category/:catId",
  STORE: "/store",
  CLUSTER: "/store/cluster",
  STORE_WALLET_HISTORY: "/store-wallet-history/:storeId",
  ORDER_SETTING: "/order-setting",
  ORDER_SETTING_DETAIL: "/order-setting/detail/:globalSettingId",
  ORDER_Add: "/order-setting/add",
  SLIDER: "/slider",
  BANNER: "/banner",
  PROMOCODE: "/promocode",
  PROMOCODE_Add: "/promocode/:promoCodeId",
  DEALOFTHEDAY: "/dealoftheday",
  DEALOFTHEDAY_Add: "/dealoftheday/:dealOfTheDayId",
  INQUIRY_: "/inquiry",
  INQUIRY_DETAILS: "/inquiry/:inquiryId/details",
  SLIDER_Add: "/slider/:sliderBannerId",
  GUEST_HOUSE: "/guest-House",
  GUEST: "/guest/guestList",
  DETAILS_PAGE: "/details-page",
  DETAILS_MASTER_PAGE: "/details-heading",
  FEATURE: "/feature",
  DELIVERY_OFF_DAY_: "/delivery-off-day",
  GUEST_ADD: "/guest/guestAdd",
  ROOM_CATEGORY: "/roomCategory",
  ROOM_FACILITY: "/facility",
  ROOMS: "/rooms",
  ROOMS_ALLOCATE: "/rooms-allocate",
  RIDER: "/rider",
  RIDER_LOCATION: "/rider-location/:latitude/:longitude",
  RIDER_DELIVERY_LOCATION: "/rider-delivery-location/:riderId",
  RIDER_ONLINE_OFFLINE: "/rider-on-off/:riderId",
  RIDER_ASSIGN_ORDER: "/rider-assign-order/:riderId",
  RIDER_WALLET_HISTORY: "/rider-wallet-history/:riderId",
  DELIVERY_SLIT_PATH: "/delivery-slot",
  ATTRIBUTE_PATH: "/attribute-master",
  ATTRIBUTE_PATH_: "/attribute",
  CATEGORY_ATTRIBUTE_MAP_PATH_: "/category-attribute-map",
  PINCODE_PATH_: "/pincode",
  DELIVERY_PINCODE_PATH_: "/delivery-pincode",
  RIDER_Add: "/rider/:riderId",
  AUTH: "/auth",
  FORGOT_PASSWORD: "/auth/forgotpassword",
  RESET_PASSWORD: "/auth/resetpassword",
};

export const SuperAdminAccess = [
  {
    path: REDIRECT_PATH.USER,
    component: <User />,
  },
  {
    path: REDIRECT_PATH.USER_DETAIL,
    component: <UserDetail />,
  },
  {
    path: REDIRECT_PATH.BRAND_MASTER,
    component: <BrandMaster />,
  },
  {
    path: REDIRECT_PATH.ORDERS,
    component: <OrdersList />,
  },
  {
    path: REDIRECT_PATH.ORDERS_DETAIL,
    component: <OrderDetails />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS,
    component: <Products />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_Add,
    component: <ProductsCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_STOCK,
    component: <ProductsStock />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_STOCK_Add,
    component: <ProductsStockCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_TAG,
    component: <ProductTagMaster />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_TAG_Add,
    component: <ProductTagMasterCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.CATEGORY,
    component: <CategoryMaster />,
  },
  {
    path: REDIRECT_PATH.CATEGORY_Add,
    component: <CategoryMasterCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.STORE,
    component: <Store />,
  },
  {
    path: REDIRECT_PATH.CLUSTER,
    component: <StoreCluster />,
  },
  {
    path: REDIRECT_PATH.STORE_WALLET_HISTORY,
    component: <StoreWalletHistoryDataTabelContainer />,
  },
  {
    path: REDIRECT_PATH.ORDER_SETTING,
    component: <OrderSetting />,
  },
  {
    path: REDIRECT_PATH.ORDER_SETTING_DETAIL,
    component: <OrderSettingDetails />,
  },
  {
    path: `${REDIRECT_PATH.ORDER_Add}`,
    component: <OrderSettingCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.SLIDER,
    component: <BannerSlider />,
  },
  {
    path: REDIRECT_PATH.BANNER,
    component: <BannersList />,
  },
  {
    path: REDIRECT_PATH.PROMOCODE,
    component: <PromoCodesList />,
  },
  {
    path: `${REDIRECT_PATH.PROMOCODE_Add}`,
    component: <PromoCodeCreateFormPopup />,
  },
  {
    path: `${REDIRECT_PATH.SLIDER_Add}`,
    component: <BannerSliderCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.DEALOFTHEDAY,
    component: <DealOfTheDayList />,
  },
  {
    path: `${REDIRECT_PATH.DEALOFTHEDAY_Add}`,
    component: <DealOfTheDayCreateFormPopup />,
  },
  {
    path: `${REDIRECT_PATH.DETAILS_PAGE}`,
    component: <DetailsPage />,
  },
  {
    path: `${REDIRECT_PATH.DETAILS_MASTER_PAGE}`,
    component: <DetailsMasterPage />,
  },
  {
    path: `${REDIRECT_PATH.FEATURE}`,
    component: <Feature />,
  },
  {
    path: `${REDIRECT_PATH.DELIVERY_OFF_DAY_}`,
    component: <DeliveryOffDay />,
  },
  {
    path: `${REDIRECT_PATH.INQUIRY_}`,
    component: <Inquiry />,
  },
  {
    path: `${REDIRECT_PATH.INQUIRY_DETAILS}`,
    component: <InquiryDetails />,
  },
  {
    path: REDIRECT_PATH.RIDER,
    component: <RidersList />,
  },
  {
    path: REDIRECT_PATH.RIDER_ONLINE_OFFLINE,
    component: <RiderOnlineOfflineDataTabelContainer />,
  },
  {
    path: REDIRECT_PATH.RIDER_WALLET_HISTORY,
    component: <RiderWalletHistoryDataTabelContainer />,
  },
  {
    path: REDIRECT_PATH.RIDER_ASSIGN_ORDER,
    component: <RiderDateWiseDeliveredDataTabelContainer />,
  },
  {
    path: REDIRECT_PATH.RIDER_LOCATION,
    component: <RiderLocation />,
  },
  {
    path: REDIRECT_PATH.RIDER_DELIVERY_LOCATION,
    component: <RiderDeliveryLocation />,
  },
  {
    path: `${REDIRECT_PATH.RIDER_Add}`,
    component: <RiderCreateFormPopup />,
  },
  {
    path: `${REDIRECT_PATH.DELIVERY_SLIT_PATH}`,
    component: <DeliverySlot />,
  },
  {
    path: `${REDIRECT_PATH.ATTRIBUTE_PATH}`,
    component: <AttributeMaster />,
  },
  {
    path: `${REDIRECT_PATH.ATTRIBUTE_PATH_}`,
    component: <Attribute />,
  },
  {
    path: `${REDIRECT_PATH.CATEGORY_ATTRIBUTE_MAP_PATH_}`,
    component: <CategoryAttributeMap />,
  },
  {
    path: `${REDIRECT_PATH.PINCODE_PATH_}`,
    component: <PincodeMaster />,
  },
  {
    path: `${REDIRECT_PATH.DELIVERY_PINCODE_PATH_}`,
    component: <DeliveryPincode />,
  },
];

export const StoreAdminAccess = [
  {
    path: REDIRECT_PATH.USER,
    component: <User />,
  },
  {
    path: REDIRECT_PATH.BRAND_MASTER,
    component: <BrandMaster />,
  },
  {
    path: REDIRECT_PATH.ORDERS,
    component: <OrdersList />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS,
    component: <Products />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_Add,
    component: <ProductsCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_STOCK,
    component: <ProductsStock />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_STOCK_Add,
    component: <ProductsStockCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_TAG,
    component: <ProductTagMaster />,
  },
  {
    path: REDIRECT_PATH.PRODUCTS_TAG_Add,
    component: <ProductTagMasterCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.CATEGORY,
    component: <CategoryMaster />,
  },
  {
    path: REDIRECT_PATH.CATEGORY_Add,
    component: <CategoryMasterCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.STORE,
    component: <Store />,
  },
  {
    path: REDIRECT_PATH.ORDER_SETTING_DETAIL,
    component: <OrderSettingDetails />,
  },
  {
    path: `${REDIRECT_PATH.ORDER_Add}`,
    component: <OrderSettingCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.PROMOCODE,
    component: <PromoCodesList />,
  },
  {
    path: `${REDIRECT_PATH.PROMOCODE_Add}`,
    component: <PromoCodeCreateFormPopup />,
  },
  {
    path: REDIRECT_PATH.DEALOFTHEDAY,
    component: <DealOfTheDayList />,
  },
  {
    path: `${REDIRECT_PATH.DEALOFTHEDAY_Add}`,
    component: <DealOfTheDayCreateFormPopup />,
  },
  {
    path: `${REDIRECT_PATH.DELIVERY_OFF_DAY_}`,
    component: <DeliveryOffDay />,
  },
  {
    path: `${REDIRECT_PATH.INQUIRY_}`,
    component: <Inquiry />,
  },
  {
    path: `${REDIRECT_PATH.INQUIRY_DETAILS}`,
    component: <InquiryDetails />,
  },
  {
    path: REDIRECT_PATH.RIDER,
    component: <RidersList />,
  },
  {
    path: `${REDIRECT_PATH.RIDER_Add}`,
    component: <RiderCreateFormPopup />,
  },
  {
    path: `${REDIRECT_PATH.DELIVERY_SLIT_PATH}`,
    component: <DeliverySlot />,
  },
];

export const PublicAccess = [
  {
    path: REDIRECT_PATH.AUTH,
    component: <Login />,
  },
  {
    path: REDIRECT_PATH.FORGOT_PASSWORD,
    component: <ForgotPassword />,
  },
  {
    path: REDIRECT_PATH.RESET_PASSWORD,
    component: <ResetPassword />,
  },
  {
    path: "*",
    component: <ErrorPage />,
  },
];
