import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild, PasswordFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import {
  Create_User,
  Put_Update_User,
  Upload_Image,
} from "../../../../Apis/userApi/UserApi";
import UserContext from "../../../../context/UserContext/UserContext";
import { Dialog } from "primereact/dialog";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Email,
  Name,
  Phone,
  Submit,
  PasswordLabel,
  ConfirmPassword,
  TYPE_FELDS,
  TypeNumber,
  CreateUser,
  Roles,
  RoleDrop,
  DialCode,
  optionsDialCode,
  Store_,
  FileType,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import {
  createUserPasswordSchema,
  createUserSchema,
} from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import { get_Store } from "../../../../Apis/storeApi/StoreApi";

const UserCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
  } = useContext(UserContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  const [filterDrop, setFilterDrop] = useState({
    storeFilter: "",
  });

  /**
   *
   * @param {event } e
   * create User & update User
   */

  const { mutate: createUser, isLoading: createUserLoader } = useMutation(
    (formData) => Create_User(formData),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const { mutate: updateUser, isLoading: updateUserLoader } = useMutation(
    (formData) => Put_Update_User(formData, orgId),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const handleUserCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = isEdit ? createUserSchema() : createUserPasswordSchema();
      await schema.validate(formData, { abortEarly: false });
      if (orgId !== -1 && isEdit === false) {
        let value = {
          ...formData,
          dialCode: formData?.dialCode?.code,
          roleId: formData?.roleId?.code,
          assignStores: formData?.assignStores?.length
            ? formData?.assignStores.map((elem) => {
                return {
                  storeId: elem?.code,
                };
              })
            : null,
          image: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
        };
        updateUser(value);
      } else {
        let value = {
          ...formData,
          dialCode: formData?.dialCode?.code,
          roleId: formData?.roleId?.code,
          assignStores: formData?.assignStores?.length
            ? formData?.assignStores.map((elem) => {
                return {
                  storeId: elem?.code,
                };
              })
            : null,
          image: formData?.image
            ? formData.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
        };
        createUser(value);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeOrgAdmin = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => {};

  const openImageUploadModal = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
        };
      });
    };
    setdialogsModal(true);
  };

  let paramsObj = {
    showAll: true,
    search: { name: filterDrop.storeFilter },
  };
  let { data: storeDrop } = useQuery(
    ["getStoreDropdown", paramsObj],
    async () => await get_Store(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <Dialog
        header={CreateUser}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse isLoading={createUserLoader || updateUserLoader} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleUserCreateAndUpdate(e)}
                >
                  <Row>
                    <div className="field mb-4 col-12 md:col-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          marginBottom: "15px",
                        }}
                      >
                        <div
                          style={{
                            height: "150px",
                            width: "185px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={formData.image}
                            alt=""
                            style={{
                              width: "80%",
                              height: "98%",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <input
                          className="custom-file-input"
                          type="file"
                          onChange={(e) => openImageUploadModal(e)}
                        />
                        <Button
                          className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                          style={{ height: "40px" }}
                        >
                          <span
                            className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                          ></span>
                          <span
                            className="p-button-label p-c"
                            style={{ fontWeight: "100" }}
                          >
                            {"Choose"}
                          </span>
                        </Button>
                      </div>
                    </div>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={Name}
                        name="name"
                        id="name"
                        type={TYPE_FELDS.text}
                        placeholder={Name}
                        value={formData.name}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.name && (
                        <span className="error-validation">{errors.name}</span>
                      )}
                    </Col>
                    <Col md="3 mb-4">
                      <FormDataFeild
                        label={Store_}
                        name="assignStores"
                        id="assignStores"
                        placeholder={Store_}
                        value={formData.assignStores}
                        handleChange={handleChangeOrgAdmin}
                        multiDropdown={true}
                        multiOption={storeDrop?.payload?.data?.map((elem) => {
                          return {
                            name: elem?.name,
                            code: elem?.storeId,
                          };
                        })}
                        search={(e) => {
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              storeFilter: e.target.value,
                            };
                          });
                        }}
                      />
                      {errors.assignStores && (
                        <span className="error-validation">
                          {errors.assignStores}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4" className="d-flex gap-4 ">
                      <div>
                        <FormDataFeild
                          label={DialCode}
                          name="dialCode"
                          id="dialCode"
                          placeholder={DialCode}
                          value={formData.dialCode}
                          handleChange={handleChangeOrgAdmin}
                          dropdown={true}
                          option={optionsDialCode}
                          search={search}
                        />
                        {errors.dialCode && (
                          <span className="error-validation">
                            {errors.dialCode}
                          </span>
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <FormDataFeild
                          label={Phone}
                          name="phone"
                          id="phone"
                          type={TYPE_FELDS.number}
                          placeholder={Phone}
                          value={formData.phone}
                          handleChange={handleChangeOrgAdmin}
                          maxlangth={TypeNumber.PHONE}
                        />
                        {errors.phone && (
                          <span className="error-validation">
                            {errors.phone}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Email}
                        name="email"
                        id="email"
                        type={TYPE_FELDS.email}
                        placeholder={Email}
                        value={formData.email}
                        handleChange={handleChangeOrgAdmin}
                      />
                      {errors.email && (
                        <span className="error-validation">{errors.email}</span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={Roles}
                        name="roleId"
                        id="roleId"
                        placeholder={Roles}
                        value={formData.roleId}
                        handleChange={handleChangeOrgAdmin}
                        dropdown={true}
                        option={RoleDrop}
                        search={search}
                      />
                      {errors.roleId && (
                        <span className="error-validation">
                          {errors.roleId}
                        </span>
                      )}
                    </Col>
                  </Row>
                  {isEdit && (
                    <Row>
                      <Col md="6 mb-4">
                        <PasswordFeild
                          label={PasswordLabel}
                          name="password"
                          id="password"
                          placeholder={PasswordLabel}
                          value={formData.password}
                          handleChange={handleChangeOrgAdmin}
                        />
                        {errors.password && (
                          <span className="error-validation">
                            {errors.password}
                          </span>
                        )}
                      </Col>
                      <Col md="6 mb-4">
                        <PasswordFeild
                          label={ConfirmPassword}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder={ConfirmPassword}
                          value={formData.confirmPassword}
                          handleChange={handleChangeOrgAdmin}
                        />
                        {errors.confirmPassword && (
                          <span className="error-validation">
                            {errors.confirmPassword}
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md="12 mb-4" className="btn-style">
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={createUserLoader || updateUserLoader}
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment>
  );
};

export default UserCreateFormPopup;
